import { DialogContent, Paper, styled } from "@mui/material";

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    "& .tui-image-editor-submenu": {
        height: "auto",
        paddingBottom: 0,
    },
    "& .tui-colorpicker-palette-button": {
        height: "16px",
        width: "16px",
    },
    "& .tui-image-editor-container.bottom .tui-image-editor-submenu > div": {
        paddingBottom: 0,
    },
    "& .tie-btn-hand, & .tie-btn-history, & .tie-btn-deleteAll, & .tie-btn-delete, & .tui-image-editor-partition, & .tui-image-editor-newline, & .tie-draw-line-select-button": {
        display: "none !important",
    },
    "& .tui-image-editor-container .tui-image-editor-submenu": {
        whiteSpace: "normal",
    },
    "& .tui-colorpicker-palette-preview": {
        fontSize: "0px",
    },
    padding: "5% !important",
    overflowY: "unset",
    flex: "unset"
}));

export const CommentItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette?.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography?.body2,
    padding: theme?.spacing(1),
    margin: '8px',
    textAlign: 'left',
    color: theme.palette?.text.secondary,
    maxWidth: 400,
}));




export const CustomDialogEditImageContent = styled(DialogContent)(({ theme }) => ({
    "& .tui-image-editor-submenu": {
        height: "auto",
        paddingBottom: 0,
    },
    "& .tui-colorpicker-palette-button": {
        height: "16px",
        width: "16px",
    },
    "& .tui-image-editor-container.bottom .tui-image-editor-submenu > div": {
        paddingBottom: 0,
    },
    "& .tie-btn-history, & .tie-btn-deleteAll, & .tie-btn-delete, & .tui-image-editor-partition, & .tui-image-editor-newline, & .tie-draw-line-select-button": {
        display: "none !important",
    },
    "& .tui-image-editor-container .tui-image-editor-submenu": {
        whiteSpace: "normal"
    },
    "& .tui-colorpicker-palette-preview": {
        fontSize: "0px",
    },
    "& .tui-image-editor": {
        height: "100% !important",
        width: "100% !important",
        paddingBottom: "50px !important",
        paddingLeft: "7% !important",
        paddingRight: "7% !important",
        justifyContent: "center !important",
        display: "flex !important",
    },
    "& .upper-canvas": {
        maxWidth: "unset !important",
        maxHeight: "unset !important",
        height: "unset !important",
        width: "100% !important"
    },
    "& .lower-canvas": {
        maxWidth: "unset !important",
        maxHeight: "unset !important",
        height: "unset !important",
        width: "100% !important"
    },
    "& .tui-image-editor-canvas-container": {
        maxWidth: "unset !important",
        maxHeight: "unset !important",
        height: "unset !important",
        width: "100% !important"
    },
    padding: "5% !important",
}));