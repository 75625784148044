import { atom } from 'recoil';
import { UserProfile } from './interface';


export const isAuthenticatedState = atom({
  key: 'isAuthenticatedState',
  default: false,
});

export const userState = atom({
  key: 'userState',
  default: {} as UserProfile,
});

export const loadingState = atom({
  key: 'loadingState',
  default: false,
});

