import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { addComment, getImage, sendConfirmedOrder, uploadImage } from "../api";
import { useEffect, useRef, useState } from "react";
import ImageEditor from "@toast-ui/react-image-editor";
import { base64ToBlob, getSelectedFolder, getSelectedRoute } from "../utils";
import { Alert, AlertTitle, Box, Card, CardContent, DialogContentText, DialogTitle, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, styled } from "@mui/material";
import "tui-image-editor/dist/tui-image-editor.css";
import { Order } from "../interface";
import Loading from "./Loading";
import { useRecoilValue } from "recoil";
import { userState } from "../state";
import { CustomDialogEditImageContent } from "../styles/common";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

declare class ImageEditorType {
  getInstance(): {
    toDataURL(data: any): string;
    startDrawingMode: (mode: string) => void;
    setBrush: (settings: { width: number; color: string }) => void;
    resetZoom: () => void;
  };
}

const myTheme = {
  "header.display": "none",
};

const ModalEditImage = ({
  imageUrl,
  username,
  profileUrl,
  userId,
  groupId,
  orders = [],
  handleClose,
  handleConfirm,
  handleBack,
  handleNext,
}: {
  imageUrl?: string;
  username?: string;
  userId?: string;
  groupId?: string;
  orders?: Order[];
  handleClose: () => void;
  handleConfirm: (
    status: boolean,
    originalImageUrl: string,
    order: Order
  ) => void;
  handleBack: () => void;
  handleNext: () => void;
  profileUrl?: string;
}): React.ReactElement => {
  const imageEditorRef = useRef<any | null>(null);
  const [base64Image, setBase64Image] = useState<string>("");
  const [route, setRoute] = useState<string>("");
  const selectedRoute = getSelectedRoute();
  const selectedFolder = getSelectedFolder();
  const [showSendImageSuccess, setShowSendImageSuccess] = useState(false);
  const [showSendImageError, setShowSendImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [menuSelected, setMenuSelected] = useState("draw");
  const userProfile = useRecoilValue(userState);
  const [zoomLevel, setZoomLevel] = useState<number>(1.0);
  const [showModalConfirmOrder, setShowModalConfirmOrder] =
    useState<boolean>(false);
  // const [drawColor, setDrawColor] = useState<string>("#ff002a"); // Set initial draw color

  useEffect(() => {
    setRoute(selectedRoute);
  }, [selectedRoute]);

  useEffect(() => {
    const loadImageFromUrl = async () => {
      try {
        if (!imageUrl) return;
        const newImageUrl = imageUrl.replace(
          "https://sgjshop-api.dodev.me",
          "https://sgjshop-api.dodev.me"
        );
        console.log(newImageUrl)
        const response = await getImage(newImageUrl);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setBase64Image(base64String);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };
    loadImageFromUrl();
  }, [imageUrl]);

  useEffect(() => {
    const editorInstance = imageEditorRef.current?.getInstance();
    if (editorInstance) {
      const handleZoomIn = () => {
        const drawButton = document.querySelector(".tie-btn-draw.active");
        const newZoomLevel = zoomLevel * 1.1;
        setZoomLevel(newZoomLevel);
        const currentDrawingMode = editorInstance.getDrawingMode();

        console.log(currentDrawingMode)
        const zoomOutButton = document.querySelector(".tie-btn-zoomOut.on");
        if(zoomOutButton) zoomOutButton.classList.remove('on');
        if (drawButton)
          drawButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));

 
      };

      const handleZoomOut = () => {
        const drawButton = document.querySelector(".tie-btn-draw.active");
        const zoomInButton = document.querySelector(".tie-btn-zoomIn.on");
        if(zoomInButton) zoomInButton.classList.remove('on');
        const newZoomLevel = zoomLevel / 1.1; 
        setZoomLevel(newZoomLevel);
        editorInstance.startDrawingMode('NORMAL');
        if (drawButton)
          drawButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));

     
      };

      const handleHand = () => {
        const drawButton = document.querySelector(".tie-btn-draw.active");
        if (drawButton)
          drawButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      };

      const zoomInButton = document.querySelector(".tie-btn-zoomIn");
      const zoomOutButton = document.querySelector(".tie-btn-zoomOut");
      const handButton = document.querySelector(".tie-btn-hand");

      if (zoomInButton) zoomInButton.addEventListener("click", handleZoomIn);
      if (handButton) handButton.addEventListener("click", handleHand);
      if (zoomOutButton) zoomOutButton.addEventListener("click", handleZoomOut);

      return () => {
        if (zoomInButton)
          zoomInButton.removeEventListener("click", handleZoomIn);
        if (zoomOutButton)
          zoomOutButton.removeEventListener("click", handleZoomOut);
        if (handButton) handButton.removeEventListener("click", handleHand);
      };
    }
  }, [base64Image]);

  const addEditImageComment = async (
    imageUrl: string,
    comment: string
  ): Promise<void> => {
    const pictureUrl = userProfile.pictureUrl ?? "";
    await addComment(
      imageUrl,
      comment,
      userProfile.userId,
      userProfile.displayName,
      pictureUrl
    );
    setCommentText("");
  };

  const handleSend = async (): Promise<void> => {
    setIsLoading(true);
    handleResetZoom();
    if (imageEditorRef.current) {
      const dataUrl = imageEditorRef.current?.getInstance().toDataURL({
        format: "jpeg",
        quality: 0.7,
      });
      const blob = base64ToBlob(dataUrl);
      const contentType = dataUrl.split(";")[0].replace("data:", "");
      const fileName = "image-edited." + contentType.split("/")[1];
      const formData = new FormData();
      formData.append("upload_file", blob, fileName);
      const response = await uploadImage(route, selectedFolder || "", formData);
      const editedImageUrl = response.data.url.replace(
        "http://localhost:8002",
        "https://sgjshop-api.dodev.me"
      );
      const {
        status,
        data: { order },
      }: { status: boolean; data: { order: Order } } = await sendConfirmedOrder(
        userId || "",
        username || "",
        editedImageUrl,
        groupId || "",
        route,
        selectedFolder || "",
        imageUrl || "",
        profileUrl || ""
      );
      if (commentText !== "") {
        await addEditImageComment(editedImageUrl, commentText);
      }
      setShowSendImageSuccess(status);
      setShowSendImageError(!status);
      setShowModalConfirmOrder(false);
      handleConfirm(status, imageUrl || "", order);
    }
    setIsLoading(false);
  };

  const handleResetZoom = () => {
    const editorInstance = imageEditorRef.current?.getInstance();
    if (editorInstance) {
      editorInstance.resetZoom();
    }
  };

  return (
    <>
      <Dialog
        open={showModalConfirmOrder}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Order Message</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField
              id="standard-multiline-flexible"
              label="Message"
              multiline
              maxRows={4}
              variant="outlined"
              required={false}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              sx={{
                width: "100%",
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowModalConfirmOrder(false);
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              handleSend();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {showSendImageSuccess && (
            <Box sx={{ marginY: 2 }}>
              <Alert severity="success">
                <AlertTitle>Sent image success</AlertTitle>
              </Alert>
            </Box>
          )}
          {showSendImageError && (
            <Box sx={{ marginY: 2 }}>
              <Alert severity="error">
                <AlertTitle>
                  Something went wrong, Please try again later.
                </AlertTitle>
              </Alert>
            </Box>
          )}
          <Box sx={{ height: "80vh" }}>
            <CustomDialogEditImageContent sx={{ height: "95%", padding: "0% 1% 1% 1% !important" }}>
              {base64Image !== "" && (
                <>
                  <ImageEditor
                    ref={imageEditorRef}
                    usageStatistics={false}
                    on
                    includeUI={{
                      loadImage: {
                        path: base64Image,
                        name: "url",
                      },
                      theme: myTheme,
                      menu: ["draw"],
                      initMenu: menuSelected,
                      uiSize: {
                        width: "100%",
                        height: "100%",
                      },
                      menuBarPosition: "center",
                    }}
                    selectionStyle={{
                      cornerSize: 20,
                      rotatingPointOffset: 70,
                    }}
                  />
                </>
              )}
            </CustomDialogEditImageContent>
          </Box>
          {orders.length > 0 && (
            <Box sx={{ flexGrow: 1, overflowY: "auto", px: 3 }}>
              <Table sx={{}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order</TableCell>
                    <TableCell align="center">State</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow
                      key={order.image_url}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <img
                          src={`${order.image_url}`}
                          srcSet={`${order.image_url}`}
                          alt={order.image_url}
                          loading="lazy"
                          style={{ height: "50px", width: "auto" }}
                        />
                      </TableCell>
                      <TableCell align="center">{order.state}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
          <Box sx={{ flexGrow: 1, overflowY: "auto", px: 3 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              marginTop={2}
            >
              <Button
                variant="outlined"
                style={{ marginRight: 5 }}
                onClick={handleBack}
              >
                {"<"}
              </Button>
              <Button
                variant="outlined"
                style={{ marginLeft: 5 }}
                onClick={handleNext}
              >
                {">"}
              </Button>
            </Grid>
          </Box>

          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
            <Button
              onClick={() => {
                handleSend();
                // setShowModalConfirmOrder(true);
              }}
              variant="contained"
            >
              Send
            </Button>
          </DialogActions>
        </>
      )}

      <Card sx={{ padding: 2, overflowY: "auto" }}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item sx={{ width: "80%" }}>
            <TextField
              id="standard-multiline-flexible"
              label="Comment"
              multiline
              maxRows={4}
              variant="outlined"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              sx={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ModalEditImage;
