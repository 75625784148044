import { CircularProgress, Stack } from "@mui/material";
import { ReactElement } from "react";

const Loading = (): ReactElement => {
  return (
    <Stack
      sx={{
        color: "grey.500",
        marginTop: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      spacing={2}
      direction="row"
    >
      <CircularProgress color="error" />
    </Stack>
  );
};

export default Loading;
