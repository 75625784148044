import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid, Stack, Slide, TextField, Typography, DialogTitle, DialogContentText, Paper } from "@mui/material";
import { CustomImage, Order, OrderComment } from "../interface";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useRecoilValue } from "recoil";
import { userState } from "../state";
import { addComment, getImage, getOderByUrl, uploadImageByOldImageUrl, changeOrderImage as apiChangeOrderImageUrl } from "../api";
import { CONFIRMED_STATE, OPEN_STATE, REJECTED_STATE, ROLES, VIEW_ONLY_STATE } from "../constant";
import { CommentItem, CustomDialogContent, CustomDialogEditImageContent } from "../styles/common";
import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import { base64ToBlob } from "../utils";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const myTheme = {
    "header.display": "none",
};

const ModalChangeStateImage = ({
    open,
    imageIndex,
    handleClose,
    handleChangeState,
    images,
    state,
    userRole,
    userId
}: {
    open: boolean;
    imageIndex: number;
    handleClose: () => void;
    handleChangeState: (image: CustomImage, newState: string, message: string) => void;
    images: CustomImage[];
    state: string;
    userRole: string;
    userId: string;
}) => {
    const imageEditorRef = useRef<any | null>(null);
    const [currentIndex, setCurrentIndex] = useState(imageIndex)
    const userProfile = useRecoilValue(userState);
    const [commentText, setCommentText] = useState("");
    const [rejectText, setRejectText] = useState("");
    const [comments, setComments] = useState<OrderComment[]>([]);
    const editImageRole = ["image_edited", "admin"]
    const [showModalConfirmReject, setShowModalConfirmReject] = useState(false);
    const [base64Image, setBase64Image] = useState<string>("");
    const [menuSelected, setMenuSelected] = useState("draw");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getComment()
    }, [currentIndex])


    useEffect(() => {
        const loadImageFromUrl = async () => {
            try {
                if (!images[currentIndex].original) return;
                const newImageUrl = images[currentIndex].original
                console.log(newImageUrl)
                const response = await getImage(newImageUrl);
                const blob = new Blob([response.data], {
                type: response.headers["content-type"],
                });
                const reader = new FileReader();
                reader.onloadend = () => {
                const base64String = reader.result as string;
                setBase64Image(base64String);
                };
                reader.readAsDataURL(blob);
            } catch (error) {
                // console.error("Error loading image:", error);
            }
        };
        if([ROLES.ADMIN,ROLES.IMAGE_EDITED].includes(userRole)){
            loadImageFromUrl();
        }
        
      }, [currentIndex, userRole]);

      useEffect(() => {
        if(base64Image != ""){
            const editorInstance = imageEditorRef.current?.getInstance();
            if (editorInstance) {  
                const handleZoomIn = () => {
                    const drawButton = document.querySelector(".tie-btn-draw.active");
                    const currentDrawingMode = editorInstance.getDrawingMode();
            
                    console.log(currentDrawingMode)
                    const zoomOutButton = document.querySelector(".tie-btn-zoomOut.on");
                    if(zoomOutButton) zoomOutButton.classList.remove('on');
                    if (drawButton)
                      drawButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));
            
             
                  };
            
                  const handleZoomOut = () => {
                    const drawButton = document.querySelector(".tie-btn-draw.active");
                    const zoomInButton = document.querySelector(".tie-btn-zoomIn.on");
                    if(zoomInButton) zoomInButton.classList.remove('on');
                    editorInstance.startDrawingMode('NORMAL');
                    if (drawButton)
                      drawButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));
            
                 
                  };
            
                  const handleHand = () => {
                    const drawButton = document.querySelector(".tie-btn-draw.active");
                    if (drawButton)
                      drawButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));
                  };
            
                  const zoomInButton = document.querySelector(".tie-btn-zoomIn");
                  const zoomOutButton = document.querySelector(".tie-btn-zoomOut");
                  const handButton = document.querySelector(".tie-btn-hand");
            
                  if (zoomInButton) zoomInButton.addEventListener("click", handleZoomIn);
                  if (handButton) handButton.addEventListener("click", handleHand);
                  if (zoomOutButton) zoomOutButton.addEventListener("click", handleZoomOut);
            


                setTimeout(()=>{
                    const changeColorButton = document.querySelector(".color-picker-value");
                    if (changeColorButton)
                        changeColorButton.dispatchEvent(new MouseEvent("click", { bubbles: true }));
    
                },100)

                setTimeout(()=>{
                    const color = document.querySelector('[value="#ff4040"]');
                    if (color)
                        color.dispatchEvent(new MouseEvent("click", { bubbles: true }));
                    setIsLoading(false);
                },200)         

                return () => {
                    if (zoomInButton)
                      zoomInButton.removeEventListener("click", handleZoomIn);
                    if (zoomOutButton)
                      zoomOutButton.removeEventListener("click", handleZoomOut);
                    if (handButton) handButton.removeEventListener("click", handleHand);
                };
            }
        }

      }, [base64Image, currentIndex]);
    
    const getComment = async () => {
        const { data }: { data: { order: Order } } = await getOderByUrl(images[currentIndex].original || "")
        setComments(data.order.comments)
    }

    const handleReject = () => {
        setShowModalConfirmReject(true);
    }

    const handleConfirm = async () => {
        const imageForChangeState = images[currentIndex]
        if([ROLES.ADMIN,ROLES.IMAGE_EDITED].includes(userRole)){
            const newImageUrl = await changeOrderImage()
            if(newImageUrl != null){
                imageForChangeState.original = newImageUrl
            }
        }
        handleChangeState(imageForChangeState, CONFIRMED_STATE, "")
    }

    const changeOrderImage = async () => {
        handleResetZoom();
        if (imageEditorRef.current) {
            const dataUrl = imageEditorRef.current?.getInstance().toDataURL({
              format: "jpeg",
              quality: 0.7,
            });
            const blob = base64ToBlob(dataUrl);
            const contentType = dataUrl.split(";")[0].replace("data:", "");
            const fileName = "image-edited." + contentType.split("/")[1];
            const formData = new FormData();
            const oldImageUrl = images[currentIndex].original
            formData.append("upload_file", blob, fileName);
            formData.append("old_image_url", oldImageUrl)
            const response = await uploadImageByOldImageUrl(formData);
            if(response.data["result"] == "success"){
                const newOrderImageUrl = response.data["url"]
                const changeOrderImageUrlResponse = await apiChangeOrderImageUrl(oldImageUrl, newOrderImageUrl)
                if(changeOrderImageUrlResponse.data["result"] == "success"){
                    return newOrderImageUrl
                }
            }
        }
        return null;
    }



    
    const handleComment = async () => {
        const pictureUrl = userProfile.pictureUrl ?? '';
        await addComment(images[currentIndex].original, commentText, userProfile.userId, userProfile.displayName, pictureUrl)
        setCommentText("")
        getComment()
    }

    const formatDateTime = (dateTime: string) => {
        const date = new Date(dateTime);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const handleConfirmRejected = async () => {
        const pictureUrl = userProfile.pictureUrl ?? '';
        const imageForChangeState = images[currentIndex]
        if([ROLES.ADMIN,ROLES.IMAGE_EDITED].includes(userRole)){
            const newImageUrl = await changeOrderImage()
            if(newImageUrl != null){
                imageForChangeState.original = newImageUrl
            }
        }
        await addComment(imageForChangeState.original, rejectText, userProfile.userId, userProfile.displayName, pictureUrl)
        setCommentText("")
        handleChangeState(imageForChangeState, REJECTED_STATE, rejectText)
    }

    const handleResetZoom = () => {
        const editorInstance = imageEditorRef.current?.getInstance();
        if (editorInstance) {
          editorInstance.resetZoom();
        }
    };

    return open ? (
        <Box sx={{ background:"white", position: "fixed",zIndex:"1101", left: "0", top:"0", width: "100%", height: "100%", overflow: "auto"}}>
            {/* <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
                maxWidth="xl"
                fullScreen
            >    */}

                <Dialog
                    open={showModalConfirmReject}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                >
                        <DialogTitle>Reject Message</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <TextField
                                id="standard-multiline-flexible"
                                label="Message"
                                multiline
                                maxRows={4}
                                variant="outlined"
                                required={true}
                                value={rejectText}
                                onChange={(e) => setRejectText(e.target.value)}
                                sx={{
                                    width: '100%',
                                }}
                            />
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={()=>{
                            setShowModalConfirmReject(false);
                        }}>Close</Button>
                        <Button onClick={()=> {
                            if(rejectText !== ""){
                                handleConfirmRejected()
                            }
                        }}>Reject</Button>
                        </DialogActions>
                </Dialog>

                {userRole == ROLES.USER && (<CustomDialogContent>
                    <img
                        src={`${images[currentIndex].original}`}
                        srcSet={`${images[currentIndex].original}`}
                        alt={images[currentIndex].caption}
                        loading="lazy"
                        style={{ height: "auto", width: "100%" }}
                    />


                </CustomDialogContent>
                )}

                {[ROLES.ADMIN,ROLES.IMAGE_EDITED].includes(userRole) && (<Box sx={{ height: "80vh", opacity: isLoading ? 0: 1 }}>
                    <CustomDialogEditImageContent sx={{ height: "95%", padding: "0% 1% 1% 1% !important" }}>
                            {base64Image !== "" && (
                                <>
                                <ImageEditor
                                    ref={imageEditorRef}
                                    usageStatistics={false}
                                    on
                                    includeUI={{
                                    loadImage: {
                                        path: base64Image,
                                        name: "url",
                                    },
                                    theme: myTheme,
                                    menu: ["draw"],
                                    initMenu: menuSelected,
                                    uiSize: {
                                        width: "100%",
                                        height: "100%",
                                    },
                                    menuBarPosition: "center",
                                    }}
                                    selectionStyle={{
                                        cornerSize: 20,
                                        rotatingPointOffset: 70,
                                    }}
                                />
                                </>
                            )}
                    </CustomDialogEditImageContent>
                </Box>)}
            

                { images[currentIndex].rejectedMessage !== "" && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                        marginTop={2}
                    >
                        <Typography>{"Rejected Message: " + images[currentIndex].rejectedMessage}</Typography>
                    </Grid>
                )}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    marginTop={2}
                >
                    <Button variant="outlined" style={{ 'marginRight': 5 }} onClick={() => {
                        if (currentIndex > 0) {
                            setBase64Image("");
                            setIsLoading(true);
                            setTimeout(()=>{
                                const newIndex = currentIndex - 1
                                setCurrentIndex(newIndex)
                            },100)

                        }
                    }}>
                        {"<"}
                    </Button>
                    <Button variant="outlined" style={{ 'marginLeft': 5 }} onClick={() => {
                        if (currentIndex < images.length - 1) {
                            setBase64Image("");
                            setIsLoading(true);
                            setTimeout(()=>{
                                const newIndex = currentIndex + 1
                                setCurrentIndex(newIndex)
                            },100)
                        }
                    }}>
                        {">"}
                    </Button>
                </Grid>
                <DialogActions>
                    {userRole == ROLES.USER && state == OPEN_STATE &&
                        <Button variant="outlined" style={{ 'marginRight': 5 }} onClick={handleReject}>
                            Reject
                        </Button>
                    }
                    { editImageRole.includes(userRole) && state == OPEN_STATE && <>
                        <Button variant="outlined" style={{ 'marginRight': 5 }} onClick={handleReject}>
                            Reject
                        </Button>
                        <Button variant="outlined" style={{ 'marginRight': 5 }} onClick={handleConfirm}>
                            Confirm
                        </Button>
                    </>}
                    { editImageRole.includes(userRole) && state == CONFIRMED_STATE && <>
                        <Button variant="outlined" style={{ 'marginRight': 5 }} onClick={handleReject}>
                            Reject
                        </Button>
                    </>}
                    { editImageRole.includes(userRole) && state == REJECTED_STATE && <>
                        <Button variant="outlined" style={{ 'marginRight': 5 }} onClick={handleConfirm}>
                            Confirm
                        </Button>
                    </>}
                    <Button onClick={handleClose} variant="outlined">
                        Close
                    </Button>
                </DialogActions>
                <DialogActions sx={{display: "block"}}>        
                    <Card sx={{ padding: 2, marginY: 3, overflowY: 'auto'}} >

                        <CardContent sx={{ padding: '0px' }}>
                            { state !== VIEW_ONLY_STATE && <>
                                <Grid container direction="column" alignItems="center" spacing={2}>
                                    <Grid item sx={{ width: '60%' }}>
                                        <TextField
                                            id="standard-multiline-flexible"
                                            label="Comment"
                                            multiline
                                            maxRows={4}
                                            variant="outlined"
                                            value={commentText}
                                            onChange={(e) => setCommentText(e.target.value)}
                                            sx={{
                                                width: '100%',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" style={{ marginRight: 5 }} onClick={handleComment}>
                                            Comment
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>}

     
                            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
                                {comments.map((comment) => {
                                    return (
                                        <>
                                            <CommentItem
                                                sx={{
                                                    my: 1,
                                                    mx: 'auto',
                                                    // textAlign: comment.username === userProfile.displayName ? 'right' : 'left',
                                                    alignSelf: comment.username === userProfile.displayName ? 'flex-end' : 'flex-start',
                                                    p: 2,
                                                }}
                                                key={comment.index}
                                            >
                                                <Stack direction="row" >
                                                    <Box sx={{ width: '100%' }}>
                                                        <Grid container justifyContent="space-between" alignItems="flex-start">
                                                            <Grid item>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {comment.username}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {formatDateTime(comment.created_time)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
                                                            {comment.comment}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </CommentItem>
                                        </>
                                    );
                                })}
                            </Box>
                        </CardContent>
                    </Card>
                </DialogActions>    
            {/* </Dialog> */}
        </Box>
    ) : <></>;
}

export default ModalChangeStateImage;