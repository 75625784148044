import { Box, Typography } from "@mui/material";
import { CustomImage, Order } from "../interface"
import StandardImageList from "./StandardImageList";
import { useEffect, useState } from "react";
import ModalEditImage from "./ModalEditImage";
import { useRecoilValue } from "recoil";
import { userState } from "../state";

const UserOrderAgain = ({
    orders,
    folder
  }: {
    orders: Order[]
    folder: string
  }): React.ReactElement => {

    const [imageUrlForEdit, setImageUrlForEdit] = useState<string>("");
    const [imageIndex, setImageIndex] = useState(0);
    const [images, setImage] = useState<CustomImage[]>([])
    const userProfile = useRecoilValue(userState);

    useEffect(()=>{
        const newImages = orders.map(order=>{
            return {
                original: order.original_image_url,
                caption: order.original_image_url,
            }
        })
        setImage(newImages)
    }, orders)

    const onClickImage = (image: CustomImage, index: number) => {
        setImageUrlForEdit("");
        setImageUrlForEdit(image.original);
        setImageIndex(index)
    };

    const changeImageEditUrl = (imageForEdit: CustomImage[], newIndex: number) => {
        setImageUrlForEdit("");
        setImageIndex(newIndex)
        setTimeout(() => {
            const image = imageForEdit[newIndex];
            if(image){
                setImageUrlForEdit(image.original);
            }
        }, 500);
    }

    const handleConfirmOrder = (status: boolean, originalImageUrl: string, newOrder:Order) => {
        if(status){
            setTimeout(()=>{
                setImageUrlForEdit("");
            },500)
        }

    }
    return <>
        {imageUrlForEdit !== "" ? (
            <ModalEditImage
                imageUrl={imageUrlForEdit}
                handleClose={() => {
                    setImageUrlForEdit("");
                }}
                handleConfirm={handleConfirmOrder}
                handleBack={()=>{
                    if(imageIndex > 0){
                    const newIndex = imageIndex - 1
                    changeImageEditUrl(images, newIndex)
                    }
                }}
                handleNext={()=>{
                    if(imageIndex < images.length - 1){
                        const newIndex = imageIndex + 1
                        changeImageEditUrl(images, newIndex)
                    }
                }}
                username={userProfile.displayName}
                groupId={userProfile.groupId}
                userId={userProfile.userId}
                profileUrl={userProfile.pictureUrl}
                orders={orders.filter(order=> order.original_image_url === imageUrlForEdit)}
            />
        ): <>
            <Box style={{ display: "flex" }}>
                <Typography variant="h5">
                {folder.replace("T", " ")}
                </Typography>
            </Box>
            <StandardImageList
                itemData={images}
                onClickImage={(image: CustomImage, index: number)=>{
                    onClickImage(image, index);
                }}

            />
        </>}


    </>
}


export default UserOrderAgain