import { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Button, Card, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from "@mui/material";
import { setSelectedFolder as setSelectedFolderToStorage, setSelectedRoute as setSetSelctedRouteToStorage } from "../utils";
import {
  getCountState,
  getFolderInOrder,
  getRoutes,
} from "../api";
import { CountStateMap, Route, RouteAndFolders } from "../interface";
import Loading from "../components/Loading";
import { userState } from "../state";
import { useRecoilValue } from "recoil";
import EditedImageList from "./EditedImageList";



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
          >
          {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface AllOrderListProps {
}

const AllOrderList = ({}: AllOrderListProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const userProfile = useRecoilValue(userState);
  const [tab, setTab] = useState(0);
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");
  const [selectedGroupName, setSelectedGroupName] = useState<string>("")
  const [routes,setRoutes]=useState<Route[]>([])
  const [routeAndFolders, setRouteAndFolders] = useState<RouteAndFolders[]>([])

  useEffect(() => {
    initialData()
  },[])

  const initialData = async() => {
    const response = await getRoutes()
    setRoutes(response.routes)
  }

  useEffect(() => {
    getFolders()
  },[routes])

  const getFolders = async () : Promise<void> => {
    setIsLoading(true);
    const newRouteAndFolders = await Promise.all(routes.map((route)=>{
      return getFoldersByRoute(route.route_name)
    }));
    
    setTimeout(() => {
      setRouteAndFolders(newRouteAndFolders.flat().filter(folder=>{
        return folder.countState.some(state => state.open > 0)
      }) );
      setIsLoading(false);
    }, 1500);
  }

  const getFoldersByRoute = async (route: string): Promise<RouteAndFolders[]> => {
    const folders = await getFolderInOrder(route, userProfile.role);
    const newRouteAndFolders = await Promise.all(folders.map((folder)=>{
      return getStateByRouteAndFolders(route, folder)
    }));
    return newRouteAndFolders
  };

  const getStateByRouteAndFolders = async (route: string, folder: string): Promise<RouteAndFolders> => {
    const newCountState = await getCountState(route, folder);
    return {
      folder: folder,
      route: route,
      countState: newCountState.filter(state => state.open > 0)
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
      setTab(newTab);
  };


  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Order List (All)" {...a11yProps(0)} />
      </Tabs>
      <CustomTabPanel value={tab} index={0}>
        {routeAndFolders.map((routeAndFolder) => {
          return (
               selectedGroupId == "" && (
                      <Card sx={{ padding: 2, marginY: 3, overflow: "auto" }} key={`${routeAndFolder.route}${routeAndFolder.folder}`}>
                          <CardContent sx={{ padding: '0px' }}>
                            <Box style={{ display: "flex", padding: '16px' }}>
                              <Typography variant="h4">{`${routeAndFolder.folder} (${routeAndFolder.route})`}</Typography>
                            </Box>
                            {routeAndFolder.countState.length > 0 && (
                                <Table sx={{}} aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Group Name</TableCell>
                                    <TableCell align="center">Open</TableCell>
                                    <TableCell align="center">Confirm</TableCell>
                                    <TableCell align="center">Reject</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {routeAndFolder.countState.map((row) => (
                                    <TableRow
                                      key={row.group_id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      
                                    >
                                      <TableCell component="th" scope="row" onClick={()=>{
                                        setSetSelctedRouteToStorage(routeAndFolder.route);
                                        setSelectedFolderToStorage(routeAndFolder.folder)
                                        setSelectedFolder(routeAndFolder.folder)
                                        setSelectedGroupId(row.group_id)
                                        setSelectedGroupName(row.group_name)
                                      }}>
                                      <Typography >
                                            {row.group_name}
                                      </Typography>
                                        
                                      </TableCell>
                                      <TableCell align="center">{row.open}</TableCell>
                                      <TableCell align="center">{row.confirmed}</TableCell>
                                      <TableCell align="center">{row.rejected}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            )}

                          </CardContent>
                      </Card>
              )
          );
        })}

        {routeAndFolders.length === 0 && (
          <Card sx={{ padding: 5, marginY: 3 }}>
            <Typography variant="subtitle2">No folder list</Typography>
          </Card>
        )}

        { selectedGroupId !== "" && <EditedImageList defaultEditedImageUrl={'no_default'} groupName={selectedGroupName} groupId={selectedGroupId} /> }

        { selectedGroupId !== "" && <Button
            style={{ marginLeft: "auto" }}
            color="primary"
            size="medium"
            onClick={() => {
              setSelectedGroupId("");
              setSelectedGroupName("");
              getFolders();
            }}
            variant="contained"
            sx={{ bgcolor: `primary.main` }}
            
          >
            Back
        </Button>}
      </CustomTabPanel>
    </>
  );
};

export default AllOrderList;
