import { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { getOderByState, getOderByUrl, updateOrder } from "../api";
import Loading from "../components/Loading";
import { CustomImage, Log, Order } from "../interface";
import { useRecoilValue } from "recoil";
import { userState } from "../state";
import StandardImageList from "../components/StandardImageList";
import { getSelectedFolder, getSelectedRoute } from "../utils";
import ModalChangeStateImage from "../components/ModalChangeStateImage";
import { CONFIRMED_STATE, OPEN_STATE, REJECTED_STATE } from "../constant";

interface EditedImageProps {
  defaultEditedImageUrl?: string;
  groupName?: string
  groupId?: string
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface DownloadImageByFolderInterface {
  images: CustomImage[];
  total: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
          >
          {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
  );
}

function a11yProps(index: number) {
return {
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
};
}

const pageSize = 10;

const EditedImageList = ({defaultEditedImageUrl, groupName, groupId}: EditedImageProps) => {
  const state = [OPEN_STATE, CONFIRMED_STATE, REJECTED_STATE]
  const [showModalImage, setShowModalImage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userProfile = useRecoilValue(userState);
  const [images, setImages] = useState<CustomImage[]>([]);
  const [folder, setFolder] = useState<string | null>(null);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [isSourceFolderExist, setIsSourceFolderExist] = useState<boolean>(true);
  const selectedFolder = getSelectedFolder();
  const selectedRoute = getSelectedRoute();
  const [totalData, setTotalData] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isHaveDefaultImage, setIsHaveDefaultImage] = useState(false);
  const [tab, setTab] = useState(0);
  const [selectedState, setSelectedState] = useState(OPEN_STATE);
  const [showChangeImageStateSuccess, setShowChangeImageStateSuccess]  = useState(false)
  const [showChangeImageStateError, setShowChangeImageStateError]  = useState(false)
  const [defaultOrder, setDefaultOrder] = useState<Order>()

  useEffect(() => {
    if(userProfile && groupId){
      getFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, groupId]);

  useEffect(()=>{

    if(defaultEditedImageUrl == "no_default"){
      setIsHaveDefaultImage(true)
    }

    if(defaultEditedImageUrl && images.length > 0 && !isHaveDefaultImage){
      const isHaveDefaultImage = images.find((item,index) => {
        if(item.original == defaultEditedImageUrl){
          setImageIndex(index)
        }
        return item.original == defaultEditedImageUrl
      })
      if(isHaveDefaultImage == undefined && (currentPage * pageSize) < totalData){
        loadMore();
      }
      if(isHaveDefaultImage){
        setShowModalImage(true)
        setIsHaveDefaultImage(true)
      }
    }



  },[images])

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 50) {
        if ((currentPage * pageSize) < totalData) {
          loadMore();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, totalData]);

  const getFolders = async (): Promise<void> => {
    if (groupId === "") return;
    setIsLoading(true);
    const folder = selectedFolder;
    const route = selectedRoute;
    const defaultState = await getDefaultState()
    setSelectedState(defaultState)
    setTab(state.indexOf(defaultState))
    await downloadImages(route, folder, defaultState);
    setTimeout(() => {
      setFolder(folder);
      setIsLoading(false);
    }, 1500);
  };

  const getDefaultState = async () => {
    if(defaultEditedImageUrl!== "" && defaultEditedImageUrl !== "no_default"){    
      const { data }: {data: {order: Order}} = await getOderByUrl(defaultEditedImageUrl||"");
      setDefaultOrder(data.order)
      return data.order.state || OPEN_STATE
    }
    return OPEN_STATE
  }

  const downloadImages = async (route: string, folder: string, state: string): Promise<DownloadImageByFolderInterface | undefined> => {
    if (folder === "") return;
    setCurrentPage(1);
    const imageByFolder = await downloadImageByFolder(route, folder, 1, state);
    setImages(imageByFolder.images);
    return imageByFolder;
  };

  const findLastMatch = (arr: Log[], value: string): Log | null => {
    return arr.reduce<Log | null>((lastMatch, item) => {
      return item.to_state === value ? item : lastMatch;
    }, null);
  };

  const downloadImageByFolder = async (
    route: string,
    folder: string,
    page: number,
    state: string
  ): Promise<DownloadImageByFolderInterface> => {
    const { data }: {data: {orders: Order[], total: number}} = await getOderByState(groupId || "", route, state, folder, page, 10);
    const newImages = data.orders.map((item: Order) => {
      return {
        original: item.image_url,
        caption: item.image_url,
        rejectedMessage: state == REJECTED_STATE ? findLastMatch(item.logs, state)?.message : "",
        groupId: item.group_id
      };
    });
    setTotalData(data.total);
    setIsSourceFolderExist(true);
    return {images: newImages, total: data.total};
  };

  const loadMore = async (): Promise<DownloadImageByFolderInterface | undefined> => {
    const page = currentPage + 1;
    const oldImages = images;
    const imageByFolder = await downloadImageByFolder(selectedRoute, selectedFolder, page, selectedState);
    const allImages = [...oldImages, ...imageByFolder.images]
    setImages(allImages);
    setCurrentPage(page);
    return { images: allImages, total: imageByFolder.total }
  };

  const onClickImage = (_image: CustomImage, index: number) => {
    setShowModalImage(true);
    setImageIndex(index);
  };

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
      setTab(newTab);
      setSelectedState(state[newTab])
      downloadImages(selectedRoute, selectedFolder, state[newTab])
  };

  const handleAutoNextPage = async (images: CustomImage[], totalImage: number) => {
      if(imageIndex < totalImage){
        if(imageIndex < images.length){
          setTimeout(()=>{
            setShowModalImage(true);
            setIsLoading(false);
          },600)
        }else{
          const newImages = await loadMore()
          handleAutoNextPage(newImages?.images || [],newImages?.total || 0)
        }
      }else{
        setShowModalImage(false);
        setIsLoading(false);
      }
  }

  const handleChangeState = async (image: CustomImage, newState: string, message: string) => {
      setIsLoading(true);
      const reusult = await updateOrder(image.original, newState, userProfile.userId, userProfile.displayName, message)
      setShowModalImage(false);
      setShowChangeImageStateError(!reusult.status)
      setShowChangeImageStateSuccess(reusult.status)
      const newImages = await downloadImages(selectedRoute, selectedFolder, selectedState)
      if( newState == CONFIRMED_STATE){
        handleAutoNextPage(newImages?.images || [], newImages?.total || 0);
      }else{
        setIsLoading(false);
      }
  }

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {!isSourceFolderExist && (
        <Box sx={{ marginY: 2 }}>
          <Alert severity="warning">
            <AlertTitle>This folder has been deleted.</AlertTitle>
          </Alert>
        </Box>
      )}

        {showChangeImageStateSuccess && (
            <Box sx={{ marginY: 2 }}>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
            </Alert>
            </Box>
        )}
        {showChangeImageStateError && (
            <Box sx={{ marginY: 2 }}>
            <Alert severity="error">
                <AlertTitle>Something went wrong, Please try again later.</AlertTitle>
            </Alert>
            </Box>
        )}
      {showModalImage && (
        <Box>
            <ModalChangeStateImage
                images={images}
                imageIndex={imageIndex}
                open={showModalImage}
                handleClose={() => {
                    setShowModalImage(false);
                }}
                state={selectedState}
                userRole={userProfile.role}
                userId={userProfile.userId}
                handleChangeState={handleChangeState}
            />
        </Box>
      )}
      {!showModalImage && <>
        <Card sx={{ padding: 1, marginY: 1 }}>
          <CardContent sx={{ padding: '0px' }}>
          <Box style={{ display: "flex", padding: '16px' }}>
            <Typography variant="h4">{groupName}</Typography>
          </Box>
            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Open" {...a11yProps(0)} />
                <Tab label="Confirmed" {...a11yProps(1)} />
                <Tab label="Rejected" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={tab} index={0}>
              <StandardImageList itemData={images} onClickImage={onClickImage} />
              <Button
                style={{ marginLeft: "auto" }}
                color="primary"
                size="medium"
                onClick={loadMore}
                variant="contained"
                sx={{ bgcolor: `primary.main` }}
                disabled={(currentPage * pageSize) >= totalData}
              >
                {`Load More: ${images.length}/${totalData}`}
              </Button>
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={1}>
              <StandardImageList itemData={images} onClickImage={onClickImage} />
                <Button
                  style={{ marginLeft: "auto" }}
                  color="primary"
                  size="medium"
                  onClick={loadMore}
                  variant="contained"
                  sx={{ bgcolor: `primary.main` }}
                  disabled={(currentPage * pageSize) >= totalData}
                >
                  {`Load More: ${images.length}/${totalData}`}
                </Button>
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={2}>
              <StandardImageList itemData={images} onClickImage={onClickImage} />
                  <Button
                    style={{ marginLeft: "auto" }}
                    color="primary"
                    size="medium"
                    onClick={loadMore}
                    variant="contained"
                    sx={{ bgcolor: `primary.main` }}
                    disabled={(currentPage * pageSize) >= totalData}
                  >
                    {`Load More: ${images.length}/${totalData}`}
                  </Button>
            </CustomTabPanel>
          </CardContent>
        </Card>
        {folder === "" && (
          <Card sx={{ padding: 5, marginY: 3 }}>
            <Typography variant="subtitle2">No folder list</Typography>
          </Card>
        )}
      </>}
    </>
  );
};

export default EditedImageList;