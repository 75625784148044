import React, { ReactElement, useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import UploadImage from "./pages/UploadImage";
import { Alert, AlertTitle, AppBar, Avatar, Box, Button, Container, Toolbar } from "@mui/material";
import liff from "@line/liff";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isAuthenticatedState, loadingState, userState } from "./state";
import EditImage from "./pages/EditImage";
import SelectRoute from "./components/SelectRoute";
import { getSelectedRoute, setSelectedRoute, getToken, setToken, setSelectedFolder } from "./utils";
import Loading from "./components/Loading";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UserProfile } from "./interface";
import { decodeToken } from "./api";
import { ROLES, ROUTE, liffId } from "./constant";
import EditedImageList from "./pages/EditedImageList";
import AllOrderList from "./pages/AllOrderList";

declare module "@mui/material/styles" {
  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(142, 0, 0)",
    },
  },
});

function App() {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedState);
  const isLoading = useRecoilValue(loadingState);
  const setIsLoading = useSetRecoilState(loadingState);
  const userProfile = useRecoilValue(userState);
  const setUserProfile = useSetRecoilState(userState);
  const [openSelectRoute, setOpenSelectRoute] = useState(false);
  const [showInvalidToken, setShowInvalidToken] = useState(false);
  const [defaultEditedImageUrl, setDefaultEditedImageUrl] = useState("");
  const [groupName, setGroupName] = useState("");
  const [selectedAllRoute, setSelectedAllRoute] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  useEffect(() => {
    const token = queryParams.get('token');
    if(token && token !== ''){
      setToken(token)
    }
    initializeLiff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkOpenSelectRoute = (userProfile: UserProfile) => {
    const selectedRoute = getSelectedRoute();
    if (
      userProfile.routes?.length > 1 &&
      !userProfile.routes?.includes(selectedRoute)
    ) {
      setOpenSelectRoute(true);
    } else if (
      userProfile.routes &&
      !userProfile.routes?.includes(selectedRoute)
    ) {
      setSelectedRoute(userProfile.routes[0]);
    }
  };

  const initializeLiff = async () => {
    setIsLoading(true);
    if (process.env.REACT_APP_IS_DISABLE_AUTH === "true") {
      setIsLoading(false);
      return;
    }
    try {
      await liff.init({ liffId });
      if (!liff.isLoggedIn()) {
        liff.login();
      } else {
        await loginSuccess();
      }
    } catch (error) {
      setIsLoading(false);
      console.error("LIFF initialization failed:", error);
    }
  };

  const loginSuccess = async () => {
    const profile = await liff.getProfile();
    const token = getToken()
    const userProfile = {} as UserProfile
    const userDetail = await decodeToken(token, profile.userId, profile.displayName);  
    if(userDetail.type !== undefined){
      setIsAuthenticated(true);
      if(userDetail.type == ROLES.ADMIN){
        checkOpenSelectRoute(userProfile);
      }else{
        setSelectedRoute(userDetail.route || "")
        setDefaultEditedImageUrl(userDetail.image_url || "")
        setGroupName(userDetail.group_name || "")
      }
      setUserProfile({...userProfile,pictureUrl: profile.pictureUrl, displayName: profile.displayName, role: userDetail.type || "", groupId: userDetail.group_id, userId: profile.userId, isAdmin: userDetail.is_admin});

    }else{
      setShowInvalidToken(true)
    }
    setIsLoading(false);
  };

  const renderComponent = (userProfile: UserProfile): ReactElement => {
    switch (userProfile.role) {
      case ROLES.ADMIN:
        return selectedAllRoute ? <AllOrderList /> : <UploadImage groupId="" groupName="" />;
      case ROLES.USER:
        return <EditImage defaultEditedImageUrl={defaultEditedImageUrl} groupName={groupName} />;
      case ROLES.IMAGE_EDITED:
        return userProfile.isAdmin ? <UploadImage defaultEditedImageUrl={defaultEditedImageUrl} groupName={groupName} groupId={userProfile.groupId || ""} /> : <></>;
    }
    return <></>;
  };
  const handleLogout = () => {
    liff.logout();
    setUserProfile({} as UserProfile);
    setSelectedRoute("");
    setToken("")
    window.location.reload();
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar component="nav" style={{ backgroundColor: "#8e0000" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
              <Avatar
                alt="logo"
                src="favicon.png"
                sx={{ marginY: 2 }}
                onClick={() => {
                  navigate(ROUTE.HOME);
                }}
              />
              {userProfile.role == ROLES.ADMIN && (
                <><Button
                  key="selectRoute"
                  onClick={() => {
                    setOpenSelectRoute(true);
                  } }
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Select Route
                </Button><Button
                  key="orderList"
                  onClick={() => {
                    setSelectedAllRoute(true);
                  } }
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                    Order List (All)
                  </Button></>
              )}
              {isAuthenticated && (
                <Button
                  key="logOut"
                  onClick={() => {
                    handleLogout()
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Logout
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Container sx={{ marginTop: "100px",padding:'0' }}>
          {userProfile.role == ROLES.ADMIN && <SelectRoute open={openSelectRoute} setOpen={setOpenSelectRoute} callback={(route: string)=>{
            setSelectedAllRoute(false);
          }} />}
          {isLoading ? (
            <Loading />
          ) : showInvalidToken ? (
              <Box>
                <Alert severity="error">
                  <AlertTitle>Invalid token</AlertTitle>
                </Alert>
              </Box>
          ) : (
            <Routes>
              <Route path={ROUTE.HOME} element={renderComponent(userProfile)} />
            </Routes>
          )}
        </Container>
      </ThemeProvider>
    </>
  );
}

export default App;
