export const base64ToBlob = (base64Data: String): Blob => {
  const base64WithoutPrefix = base64Data.replace(/^data:[^;]+;base64,/, "");
  const byteCharacters = atob(base64WithoutPrefix);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: "" });
};

export const getSelectedRoute = () => localStorage.getItem("selectedRoute") || ""

export const setSelectedRoute = (route: string) => {
  localStorage.setItem("selectedRoute", route);
}

export const getSelectedFolder = () => localStorage.getItem("selectedFolder") || ""

export const setSelectedFolder = (folder: string) => {
  localStorage.setItem("selectedFolder", folder);
}

export const getToken = () => localStorage.getItem("token") || ""

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
}

