import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { CustomImage } from "../interface";
import { Checkbox, ImageListItemBar, useMediaQuery } from "@mui/material";

const StandardImageList = ({
  itemData = [],
  onClickImage = () => {},
  handleSelectedImageChange,
}: {
  itemData: CustomImage[];
  onClickImage?: (image: CustomImage, index: number) => void;
  handleSelectedImageChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    imageSrc: string
  ) => void;
}): React.ReactElement => {
  const isXs = useMediaQuery("(max-width:600px)");
  return (
    <ImageList cols={isXs ? 4 : 4} gap={4}>
      {itemData.map((item,index) => (
        <ImageListItem key={item.original}>
          <img
            // style={{"opacity": justOrder.includes(item.original) ? 0.2 : 1.0 }}
            src={`${item.original}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.original}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.caption}
            loading="lazy"
            onClick={() => {
              onClickImage(item,index);
            }}
          />
          {handleSelectedImageChange && (
            <ImageListItemBar
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                  "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
              }}
              position="top"
              actionIcon={
                <Checkbox
                  onChange={(event) => {
                    handleSelectedImageChange(event, item.original);
                  }}
                />
              }
              actionPosition="right"
            />
          )}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default StandardImageList;
