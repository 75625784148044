import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getOderList } from "../api";
import StandardImageList from "./StandardImageList";
import { CustomImage, Log, Order } from "../interface";
import ModalChangeStateImage from "./ModalChangeStateImage";
import { CONFIRMED_STATE, OPEN_STATE, REJECTED_STATE, VIEW_ONLY_STATE } from "../constant";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
  
function a11yProps(index: number) {
return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
};
}


const UserOrderHistoryAll = ({
    route,
    userId,
    countEdited,
    userRole,
    username,
    folder
  }: {
    route: string
    userId: string
    countEdited: number
    userRole: string
    username: string
    folder: string
  }): React.ReactElement => {
    const [tab, setTab] = useState(0);
    const [imageStateOpen, setImageStateOpen] = useState<CustomImage[]>([]);
    const [imageStateConfirmed, setImageStateConfirmed] = useState<CustomImage[]>([]);
    const [imageStateRejected, setImageStateRejected] = useState<CustomImage[]>([]);
    const [showModalChangeStateImage, setShowModalChangeStateImage] =useState(false);
    const [imageIndex,setImageIndex] = useState(0);
    useEffect(()=>{
        getOrders()
    },[countEdited])

    const findLastMatch = (arr: Log[], value: string): Log | null => {
        return arr.reduce<Log | null>((lastMatch, item) => {
          return item.to_state === value ? item : lastMatch;
        }, null);
      };

    const getCustomImage = (data: Order[], state: string) => {
        return data.filter((item: Order) => item.state == state).map((item: Order)=> {
            return {
                original: item.image_url,
                caption: item.image_url,
                id: item._id,
                rejectedMessage: state == REJECTED_STATE ? findLastMatch(item.logs, state)?.message : "",
                groupId: item.group_id,
                state: item.state
              };
        })
    }
    
    const getOrders = async () => {
        const { data = [] } = await getOderList(route, userId, folder);
        setImageStateOpen(getCustomImage(data, OPEN_STATE))
        setImageStateRejected(getCustomImage(data, REJECTED_STATE))
        setImageStateConfirmed(getCustomImage(data, CONFIRMED_STATE))
    }

    const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
        setTab(newTab);
    };

    const onClickImage = (_image: CustomImage, index: number, state: string) => {
        setImageIndex(index);
        setShowModalChangeStateImage(true)
    };

    const getImages = (tab: number): CustomImage[] => {
        const imageList = [imageStateOpen, imageStateConfirmed, imageStateRejected]
        return imageList[tab]
    }

    const handleChangeState = async (_image: CustomImage, _newState: string, _message: string) => {}

    return <>

        {showModalChangeStateImage && (
            <ModalChangeStateImage
                images={getImages(tab)}
                imageIndex={imageIndex}
                open={showModalChangeStateImage}
                handleClose={() => {
                    setShowModalChangeStateImage(false);
                }}
                state={VIEW_ONLY_STATE}
                userRole={userRole}
                userId={userId}
                handleChangeState={handleChangeState}
            />
        )}
        { !showModalChangeStateImage && <>
                <Box style={{ display: "flex", padding: '10px' }}>
                        <Typography>{folder}</Typography>
                </Box>
                <Card sx={{ padding: 2, marginY: 1 }} >

                    <CardContent sx={{ padding: '0px' }}>
                        <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Open" {...a11yProps(0)} />
                            <Tab label="Confirmed" {...a11yProps(1)} />
                            <Tab label="Rejected" {...a11yProps(2)} />
                        </Tabs>
                        <CustomTabPanel value={tab} index={0}>
                            <StandardImageList itemData={imageStateOpen} onClickImage={(_image: CustomImage, index: number) => {
                                onClickImage(_image, index, VIEW_ONLY_STATE)
                            }} />
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={1}>
                            <StandardImageList itemData={imageStateConfirmed} onClickImage={(_image: CustomImage, index: number) => {
                                onClickImage(_image, index, VIEW_ONLY_STATE)
                            }} />
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={2}>
                            <StandardImageList itemData={imageStateRejected} onClickImage={(_image: CustomImage, index: number) => {
                                onClickImage(_image, index, VIEW_ONLY_STATE)
                            }} />
                        </CustomTabPanel>
                    
                    </CardContent>
                </Card>
            </>
        }
    </>
  }

  export default UserOrderHistoryAll;