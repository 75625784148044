import axios, { AxiosResponse } from "axios";
import { ApiResponse, CountStateResponse, CreateFolder, DeleteFolder, EditedImageResponse, ImageListResponse, RouteResponse, UserProfile, UserType } from "./interface";

export const getUserProfile = async (userId: string): Promise<UserProfile> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/get_user_profile/${userId}`
  const response = await axios.get(url)
  const { user_profile = {} } = response.data
  return user_profile
}

export const getImage = async (imageUrl: string): Promise<AxiosResponse<any, any>> => {
  const response = await axios.get(imageUrl, {
    responseType: "arraybuffer",
  });
  return response
}

export const uploadImage = async (route: string, folder: string, formData: FormData): Promise<AxiosResponse<any, any>> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/upload-edited-image/${route}/${folder}`
  const response = await axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  return response
}

export const uploadImageByOldImageUrl = async (formData: FormData): Promise<AxiosResponse<any, any>> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/upload-edited-image-by-old-image-url`
  const response = await axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  return response
}

export const changeOrderImage = async (imageUrl: string, newImageUrl: string): Promise<AxiosResponse<any, any>> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/change_order_image`
  const requestBody = {
    image_url: imageUrl,
    new_image_url: newImageUrl,
  };
  const response = await axios.post(url, requestBody)
  return response
}

export const sendImage = async (imageUrl: string, userUUID: string) => {
  const requestBody = {
    original_content_url: imageUrl,
    line_uuid: userUUID,
  };
  const url = `${process.env.REACT_APP_BACKEND_URL}/send_line_image`
  const response = axios.post(url, requestBody)
  return response
};

export const createFolder = async (data: CreateFolder): Promise<AxiosResponse<any, any>> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/create_folder`
  const response = await axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  return response
}

export const deleteFolder = async (data: DeleteFolder): Promise<AxiosResponse<any, any>> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/delete_folder`
  const response = await axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  return response
}

export const getFolder = async (route: string, role: string): Promise<string[]> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/get_folder/${route}/${role}`
  const response = await axios.get(url)
  const { folder_names = [] } = response.data
  return folder_names
}

export const getFolderInOrder = async (route: string, role: string): Promise<string[]> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/get_folder_in_order/${route}/${role}`
  const response = await axios.get(url)
  const { folder_names = [] } = response.data
  return folder_names
}

export const getFolderInOrderByUser = async (route: string, user_id: string): Promise<string[]> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/get_folder_in_order_by_user/${route}/${user_id}`
  const response = await axios.get(url)
  const { folder_names = [] } = response.data
  return folder_names
}



export const getImagesUrl = async (route: string, folder: string, pageSize: number, page: number, user_id: string, isAdmin: boolean, groupId: string): Promise<ImageListResponse> => {
  const res = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/v2/get_images/${route}/${folder}/${pageSize}/${page}/${user_id}/${isAdmin}/${groupId}`
  );
  return res.data;
}


export const getEditedImagesUrl = async (route: string, folder: string, pageSize: number, page: number): Promise<EditedImageResponse> => {
  const res = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/get_edited_images/${route}/${folder}/${pageSize}/${page}`
  );
  return res.data;
}

export const getCountState = async (route: string, folder: string): Promise<CountStateResponse[]> => {
  const res = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/count-state/${route}/${folder}`
  );
  return res.data;
}

export const deleteImages = async (route: string, folder: string, images: string[]): Promise<string> => {
  const res = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/delete_images/${route}/${folder}`,
    images
  );
  return res.data.result;
}

export const uploadImageToFolder = async (formData: FormData, config: any): Promise<string> => {
  const res = await axios
    .post("https://sgjshop-api.dodev.me/upload_image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...config
    })
  return res.data.url
}

export const getRoutes = async (): Promise<RouteResponse> => {
  const res = await axios.get(
    `${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/routes`
  );
  return res.data;
}

export const updateOrder = async (image_url: string, state: string, user_id: string, username: string, message: string): Promise<ApiResponse> => {
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/update-order`, {
      "image_url": image_url,
      "state": state,
      "user_id": user_id,
      "username": username,
      "message": message
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })

  return {
    "status": response.status === 200,
    "data": null
  }
}



export const getOderByUrl = async (url: string): Promise<ApiResponse> => {
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/order-by-url`, {
      "url": url
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })

  return {
    "status": response.status === 200,
    "data": response.data
  }
}

export const getOderByState = async (groupId: string, route: string, state: string, folder: string, page: number, pageSize: number): Promise<ApiResponse> => {
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/order-by-state`, {
      "group_id": groupId,
      "route": route,
      "state": state,
      "folder": folder,
      "page": page,
      "page_size": pageSize
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })

  return {
    "status": response.status === 200,
    "data": response.data
  }
}

export const getOderList = async (route: string, user_id: string, folder: string): Promise<ApiResponse> => {
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/orders`, {
      "route": route,
      "user_id": user_id,
      "folder": folder
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })

  return {
    "status": response.status === 200,
    "data": response.data
  }
}

export const getAllOderListByUser = async (route: string, user_id: string): Promise<ApiResponse> => {
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/orders-all`, {
      "route": route,
      "user_id": user_id
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })

  return {
    "status": response.status === 200,
    "data": response.data
  }
}





export const sendGroup = async (route: string, folder: string, username: string): Promise<ApiResponse> => {
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/line-group/send`, {
      "route": route,
      "folder": folder,
      "username": username
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })

  return {
    "status": response.status === 200,
    "data": response.data
  }
}

export const sendConfirmedOrder = async (userId: string, username: string, imageUrl: string, groupId: string, route: string, folder: string, originalImageUrl: string, profileUrl: string): Promise<ApiResponse> => {
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/line-group/send-image`, {
      "group_id": groupId,
      "image_url": imageUrl,
      "username": username,
      "user_id": userId,
      "route": route,
      "folder": folder,
      "original_image_url": originalImageUrl,
      "profile_url": profileUrl
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  return {
    "status": response.status === 200,
    "data": response.data
  }
}


export const decodeToken = async (token: string, userId: string, username: string): Promise<UserType> => {
  const url = `${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/decode-token/${token}/${userId}/${username}`
  const response = await axios.get(url)
  return response.data
}


export const checkFolderExist = async (route: string, folder: string): Promise<boolean> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/check_folder_exist/${route}/${folder}`
  const response = await axios.get(url)
  const { result = false } = response.data
  return result
}

export const addComment = async (image_url: string, comment: string, user_id: string, username: string, pictureUrl: string): Promise<ApiResponse> => {
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_SGJORDER_URL}/api/add-comment`, {
      "image_url": image_url,
      "comment": comment,
      "user_id": user_id,
      "username": username,
      "pictureUrl": pictureUrl,
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })

  return {
    "status": response.status === 200,
    "data": null
  }
}