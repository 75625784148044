import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import { useRef } from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalUpload = ({
  open,
  handleClose,
  handleConfirm,
  onUploadData,
}: {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  onUploadData: (data : any) => void;
}): React.ReactElement => {
  const pond = useRef(null);
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="xl"
        fullScreen
      >
        <DialogTitle>Upload Image</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {/* {content} */}
          </DialogContentText>
          <FilePond
            ref={pond}
            files={[]}
            allowMultiple={true}
            maxFiles={200}
            server={{
              process: (fieldName, file, metadata, load, error, progress, abort) => {
                // Handle file upload
                console.log(fieldName, file, metadata);
                onUploadData(file);
              },
              revert: (uniqueFileId, load, error) => {
                // Handle file removal
                console.log(uniqueFileId);
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalUpload;
